import { lazy } from "react";
import { Navigate } from "react-router-dom";

/****Layouts*****/
const FullLayout = lazy(() => import("../layouts/FullLayout.js"));

/***** Pages ****/

const Starter = lazy(() => import("../views/Starter.js"));
const About = lazy(() => import("../views/About.js"));
const Alerts = lazy(() => import("../views/ui/Alerts"));
const Badges = lazy(() => import("../views/ui/Badges"));
const Buttons = lazy(() => import("../views/ui/Buttons"));
const Cards = lazy(() => import("../views/ui/Cards"));
const Grid = lazy(() => import("../views/ui/Grid"));
const Tables = lazy(() => import("../views/ui/Tables"));
const Forms = lazy(() => import("../views/ui/Forms"));
const Breadcrumbs = lazy(() => import("../views/ui/Breadcrumbs"));
const Blogs = lazy(() => import("../views/ui/Blogs"));
const Categories = lazy(() => import("../views/ui/Categories"));
const Videos = lazy(() => import("../views/ui/Videos"));
const Services = lazy(() => import("../views/ui/Services"));
const DentalProf = lazy(() => import("../views/ui/DentalProf"));
const Appointments = lazy(() => import("../views/ui/Appointments"));
const Contacts = lazy(() => import("../views/ui/Contacts"));
const Login = lazy(() => import("../views/ui/Login"));

/*****Routes******/

const ThemeRoutes = [
  { path: "/login", exact: true, element: <Login /> },
  {
    path: "/",
    element: <FullLayout />,
    children: [
      { path: "/", element: <Navigate to="/appointments" /> },

      { path: "/blogs", exact: true, element: <Blogs /> },
      { path: "/categories", exact: true, element: <Categories /> },
      { path: "/videos", exact: true, element: <Videos /> },
      { path: "/services", exact: true, element: <Services /> },
      { path: "/dentalprof", exact: true, element: <DentalProf /> },
      { path: "/appointments", exact: true, element: <Appointments /> },
      { path: "/contacts", exact: true, element: <Contacts /> },

      { path: "/starter", exact: true, element: <Starter /> },
      { path: "/about", exact: true, element: <About /> },
      { path: "/alerts", exact: true, element: <Alerts /> },
      { path: "/badges", exact: true, element: <Badges /> },
      { path: "/buttons", exact: true, element: <Buttons /> },
      { path: "/cards", exact: true, element: <Cards /> },
      { path: "/grid", exact: true, element: <Grid /> },
      { path: "/table", exact: true, element: <Tables /> },
      { path: "/forms", exact: true, element: <Forms /> },
      { path: "/breadcrumbs", exact: true, element: <Breadcrumbs /> },
    ],
  },
];

export default ThemeRoutes;
